import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'rw-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
