import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rw-category-link',
  templateUrl: './category-link.component.html',
  styleUrls: ['./category-link.component.scss']
})
export class CategoryLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
